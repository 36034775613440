/**
 * Namespace for all JavaScript code.
 * @namespace
 */
window.untlMeta || ( window.untlMeta = {} );

/**
 * storage for misc variables
 * @type {Object}
 */
untlMeta.variables = {
	/** today's date. */
	now: dayjs(),
	/** prefix for local & session storage variables */
	storagePrefix: "untl.meta",
	/** color classes suffix patterns for color cycling */
	colorCycleClasses : [
		"default",
		"explore",
		"caution",
		"notice",
		"info",
	],
  /** failure classes for EDTF */
  edtfFailureClasses: [
    "badge",
    "bg-danger",
    "invalid",
  ],
	/** table-elements */
	colorCycleTableEls: [
	  "TABLE",
	  "TBODY",
	  "THEAD",
	  "TR",
	  "HR",
	  "TD",
	],
};


/**
 * cache for generalized selectors.
 * @namespace
 */
untlMeta.selectors = {
	body  : document.querySelector("body"),
	main  : document.querySelector("#main"),
};


/**
 * storage for localized configuration objects.
 * configs typically added inline via Django template tags in HTML.
 * @namespace
 */
untlMeta.configurations = {};


/**
 * common URLs, current page URLs stringified and current search parameters object
 * @namespace
 */
untlMeta.urls = {
	portal				                            : "https://texashistory.unt.edu",
	digitalLibrary		                        : "https://digital.library.unt.edu",
	gateway				                            : "https://gateway.okhistory.org",
	untlNames 			                          :	"https://digital2.library.unt.edu/name",
	edtfValidator                             : "https://digital2.library.unt.edu/edtf/isValid.json",
	locLinkedData 		                        : "https://id.loc.gov",
	thisDomain                                : `${location.protocol}//${location.host}`,
  thisDomainPath                            : `${location.protocol}//${location.host}${location.pathname}`,
  thisDomainPathWithParams                  : `${location.protocol}//${location.host}${location.pathname}${location.search}`,
  thisDomainPathWithParamsForEmailClients   : `${location.protocol}//${location.host}${location.pathname}${location.search.replace(/&/g, '%26')}`,
  thisFullHref                              : location.href,
  thisHash                                  : location.hash,
  pageSearchParams                          : (new URL(location)).searchParams,
};


/**
 * Various functions serving either a utility
 * or self-executing
 * @namespace
 */
untlMeta.functions = {


	/** @function showElement
	 *  Utility; Make element visible
	 *
	 *  @param  {HTMLElement} element       - element selector
	 */
	showElement :  (element) => {
  		element.style.display = "";
  		element.classList.remove("d-none");
	},


	/** @function hideElement
	 *  Utility; Visually hide an element
	 *
	 *  @param  {HTMLElement} element       - element selector
	 */
	hideElement :  (element) => {
  		element.style.display = "none";
  		element.classList.add("d-none");
	},


	/** @function debounce
	 *  Utility; Slow down the execution of a repeatedly called function
	 *
	 *  @param  {function} invoked      - function to invoke
	 *  @param  {number}   wait        - milliseconds to wait
	 */
	debounce : (invoked, wait) => {
	  let timeoutId;
	  return (...args) => {
	    const later = () => {
	      clearTimeout(timeoutId);
	      invoked(...args);
	    };
	    if (timeoutId) {
	      clearTimeout(timeoutId);
	    }
	    timeoutId = setTimeout(later, wait);
	  };
	},


	/** @function decodeHtmlEntity
	 *  Utility; Transform string containing HTML entities/Unicode into a decoded version.
	 *
	 *  @param   {String} string      - string to operate on
	 *  @return  {String} wait        - decoded string
	 */
	decodeHtmlEntity: function(string){
		const doc = new DOMParser().parseFromString(string, "text/html");
  	return doc.documentElement.textContent;
	},


	/** @function setAttributes
	 *  Utility; Set multiple HTML attributes at once
	 *
	 *  @param  {HTMLElement} element            - element on which to set attributes
	 *  @param  {Object}      attributesObject   - each key becomes an HTML attribute, value it's value.
	 */
	setAttributes: (element, attributesObject) => {
		Object.keys(attributesObject).forEach(key => {
			element.setAttribute(key, attributesObject[key]);
		});
	},


	/** @function locSuggest2UrlConstructor
	 *  NOTE: Function call is deferred to future feature implementation
	 *  Utility; Constructs a JavaScript URL object for a result set from the LOC's Suggest2 service
	 *  documented at https://id.loc.gov/techcenter/searching.html
	 *
	 *  @param  {Object} params             - URL parameters to be passed to LOC
	 *  @param  {String} params.path        - id.loc URL path for a specific vocabulary; HIGHLY suggested. No leading/trailing slashes.
   *  @param  {String} params.q           - search query string
   *  @param  {String} params.searchtype  - "leftanchored" or "keyword"; Default='keyword'
   *  @param  {Number} params.count 		  - is the number of results to return (up to 1,000); Default=25
   *  @param  {Number} params.offset 		  - start from if you are paging through results
   *
   *  @returns {URLObject} suggestUrl		 - URL object for the LOC Suggest2 service
	 */
	locSuggest2UrlConstructor: function(params){
		//* domain name, ensure no trailing "/" */
		const domain = untlMeta.urls.locLinkedData. replace(/\/+$/, '');
		let path;

		/** ensue no leading/trailing slashes, and return "some/path/suggest2", else "/suggest2". */
		if ( params?.path !== undefined ) {
			path = `${params.path.replace(/^\/|\/$/g, "")}/suggest2`;
		} else {
			path = "/suggest2";
		}
		/** create a new URL and base path */
		let suggestUrl = new URL(path, domain);

		/** append q query param if present */
		if ( params?.q !== undefined ) {
			suggestUrl.searchParams.append("q", params.q);
		}
		/** append searchtype query param if present, default to "keyword" */
		if ( params?.searchtype !== undefined ) {
			suggestUrl.searchParams.append("searchtype", params.searchtype);
		} else {
			suggestUrl.searchParams.append("searchtype", "keyword");
		}
		/** append count query param if present, default to 25. */
		if ( params?.count !== undefined ) {
			suggestUrl.searchParams.append("count", params.count);
		} else {
			suggestUrl.searchParams.append("count", "25");
		}
		//* append offset query param if present. */
		if ( params?.offset !== undefined ) {
			suggestUrl.searchParams.append("offset", params.offset);
		}
		return suggestUrl;
	},



	/** @function initColorCycle
	 *  Self Executing; rotate CSS colors for elements on-click.
	 */
	initColorCycle: (() => {

		/** define class strings for background cycles based on shared suffix pattern. */
		const colorCycleBg = untlMeta.variables.colorCycleClasses.map(item => `text-bg-${item}`);
		const colorCycleTable = untlMeta.variables.colorCycleClasses.map(item => `table-${item}`);

		/** @function _cycleColors
		 *  color cycling function
		 */
		const _cycleColors = (element, colors) => {
		  const currentClass = Array.from(element.classList).find(c => colors.includes(c));
		  if (!currentClass) {
		    return;
		  }
		  const currentIndex = colors.indexOf(currentClass);
		  const nextIndex = (currentIndex + 1) % colors.length;
		  const nextClass = colors[nextIndex];
		  element.classList.remove(currentClass);
		  element.classList.add(nextClass);
		}

		/**
     * attach click event to the document to account for post-load insertion
     *
     * @listens click
     */
		document.addEventListener("click", function(e){
			const el = e.target;
			/** ignore unless the element or parent have a `cycle-colors` class */
			if (!el.closest('.cycle-colors')) {
				return;
		  }
		  /** element to cycle classes on */
		  const cyclingElement = el.closest(".cycle-colors");
			/** apply different classes to tables than other elements */
	    if ( untlMeta.variables.colorCycleTableEls.includes(cyclingElement.tagName) ){
	    	_cycleColors(cyclingElement, colorCycleTable);
	    } else {
	    	_cycleColors(cyclingElement, colorCycleBg);
	    }
		});
	})(),


	/** @function makeCustomMessage
	 *  Self Executing; let the user add a custom message to the UI in order to pass
	 *  instructions to another editor about the current screen.
	 */
	makeCustomMessage : (() => {
		/**
		 * HTML Selectors
		 * @type {Object}
		 */
		const selectors = {
	      customMessageForm     : document.querySelector("#custom-page-message-form"),
	      customMessageText     : document.querySelector("#custom-page-message-text"),
	      removeCustomMessage   : document.querySelector("#custom-page-message-clear"),
		};
		/**
     * refresh the current page with `message` param set from user input.
     *
     * @listens submit
     */
		selectors?.customMessageForm.addEventListener('submit', (event) => {
			/** Prevent submission */
			event.preventDefault();
			/** copy of URL search params with newly appended message text */
			const searchParams = untlMeta.urls.pageSearchParams;
			searchParams.set('message', selectors.customMessageText.value);
			/** refresh the page with new param added */
			window.location.href = `${untlMeta.urls.thisDomainPath}?${searchParams.toString()}`;
		});
		/**
     * refresh the current page and clear user `message` param/rendered value.
     *
     * @listens click
     */
		selectors?.removeCustomMessage.addEventListener('click', (_) => {
			/** clear the text  input */
			selectors.customMessageText.value = "";
			/** copy of current URL search param with message removed */
			const searchParams = untlMeta.urls.pageSearchParams;
			searchParams.delete('message');
			/** refresh the page with message removed */
			window.location.href = `${untlMeta.urls.thisDomainPath}?${searchParams.toString()}`;
		});
 	})(),


 	/** @function fixResetLinkParams
 	 *  Self Executing; removes various query parameters from reset-style links that
 	 *  can't be cleared by other means.
 	 */
 	fixResetLinkParams : (() => {
 		/**
 		 * array of HTML Link elements and associated URL parameters to remove.
 		 */
		const elementsAndParams = [
			{
				el     : document.querySelector("#remove-filters-button"),
		    params : ["fq", "v", "field", "qfr", "fld"],
		  }, {
				el     : document.querySelector("#search-form-reset"),
		    params : ["q"],
		  },
      { el     : document.querySelector("#navbar-link-browse-dashboard"),
        params : ["q"],
      },
      { el     : document.querySelector("#navbar-link-browse-fields"),
        params : ["q"],
      },
      { el     : document.querySelector("#navbar-link-browse-count"),
        params : ["q"],
      },
      { el     : document.querySelector("#navbar-link-browse-cluster"),
        params : ["q"],
      },
		];
		/** @function _removeQueryParams
		 *  remove each parameter in an array from an element
		 *
		 *  @param  {HTMLElement} link       Link element
		 *  @param  {Array}       paramNames parameter arguments to be removed
		 */
		const _removeQueryParams = (link, paramsToRemove) => {
		  /** bail if there is no element */
		  if (!link) return;
		  /** get the URL and remove the specified parameters */
		  const url = new URL(link.href);
		  paramsToRemove.forEach(param => url.searchParams.delete(param));
		  /** look for cases of params without values and remove those */
		  url.searchParams.forEach((value, key) => {
		    if (!value) url.searchParams.delete(key);
		  });
		  link.href = url.href;
		  return link.href;
		}
		/** loop over elements and remove params */
		elementsAndParams.forEach((item) => {
			_removeQueryParams(item.el, item.params);
		});
 	})(),


 	/** @function initBooststrapOptIns
 	 *  Self Executing; Bootstrap 5 opt-in tools/behaviors.
 	 *  Note: Multiple tools cannot init on the same selector/context.
 	 */
	initBooststrapOptIns: (() => {
		/** standard Bootstrap Tooltips */
		new bootstrap.Tooltip(untlMeta.selectors.body, {
            selector: '[data-bs-toggle="tooltip"]'
    });
    /** standard Bootstrap Popovers */
		new bootstrap.Popover(untlMeta.selectors.main, {
            selector: '[data-bs-toggle="popover"]'
    });
    /** Special CSS tooltips that should only render when the element
     *  is in a state of overflow
     */
		const overflowTooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip-overflow"]'));
		const overflowTooltipList = overflowTooltipTriggerList.map(function (overflowTooltipTriggerEl) {
		  if ( overflowTooltipTriggerEl.scrollHeight > overflowTooltipTriggerEl.clientHeight || overflowTooltipTriggerEl.scrollWidth > overflowTooltipTriggerEl.clientWidth )  {
		  	return new bootstrap.Tooltip(overflowTooltipTriggerEl);
		  }
		});
	})(),


 	/** @function initGenericClipboard
 	 *  Self Executing; default init of clipboard.js.
 	 *  invoked by adding a data-clipboard-trigger="#some=selector" on an input/textarea,
	 *  or via a data-clipboard-text="{{ value }}" on the trigger element
 	 */
	initGenericClipboard: (() => {
		const clipboardTarget = new ClipboardJS('.to-clipboard');
	})(),


 	/** @function initClipboardClosestValue
	 *  clipboard.js custom text extension for copying text in attribute values of non-input elements.
	 *  set a class of .clipboard-closest-value on the button element that triggers the copy action
	 *  in the HTML doc, on a parent element, set the value via data-untl-value
	 *  NOTE: attribute values should be encoded.
	 *  ex: <div data-untl-value="{{ encoded_value }}">...<button class="clipboard-closest-value">...
	 */
	initClipboardClosestValue: (() => {
		const clipboardClosestTarget = new ClipboardJS('.clipboard-closest-value', {
			text: (triggeringElement) => {
				let closestValue = triggeringElement.closest("[data-untl-value]").dataset.untlValue || "",
				    decodedValue = untlMeta.functions.decodeHtmlEntity(closestValue);
		    return decodedValue;
		  }
		});
	})(),

}; // end window.untlMeta.functions
